function hideSearch() {
  $(".search-feature__form").velocity({
    opacity: 0,
    scale: [0.8, 1],
  }, 300, 'easeInOutCubic', function() {
    $(".search-feature").velocity('fadeOut', 400, 'easeInCubic').removeClass('visible');
  });

  $('body').removeClass('overlay');
}

function showSearch() {
  $(".search-feature").velocity('fadeIn', 400, 'easeInCubic', function() {
    $(".search-feature__form").velocity({
      opacity: 1,
      scale: [1, 0.8],
    }, 300, 'easeInOutCubic', function() {
      if(BrowserDetect.browser !== 'Explorer') {
        $(".search-form__search").focus();
      }
    });

    $('body').addClass('overlay');
    $(this).addClass('visible');
  });
}

$(function(){
  // Show that search overlay
  $('.search-feature__toggle').click(function(e){
    e.preventDefault();
    showSearch();
  });

  // Hide that search overlay
  $('.search-feature__close').click(function(e){
    hideSearch();
  });

  // Stahp
  $('.search-feature form').on('click', function(e) {
    e.stopPropagation();
  });

  $(document).keyup(function(e) {
    if (e.keyCode === 27 && $('.search-feature').hasClass('visible')) {
      hideSearch();
    }
  });

  $('.search-form').submit(function(e) {
    e.preventDefault();
    var filter = {};

    filter.s = $('input[name="s"]').val();
    filter.intensity = $('input[name="intensity"]:checked').val();
    var s_sample = $('input[name="sample"]:checked').length;
    var s_dropin = $('input[name="dropin"]:checked').length;
    var s_days = [];

    $('input[name="day[]"]').each(function() {
      if ($(this).is(':checked')) {
        s_days.push($(this).val());
      }
    });

    if (s_sample) {
      filter.sample = s_sample;
    }

    if (s_dropin) {
      filter.dropin = s_dropin;
    }

    if (s_days.length > 0) {
      filter.day = s_days;
    }

    var Ajax = {
      ajaxurl: "/wp-admin/admin-ajax.php"
    };

    $('.search__heading').addClass('is-visible');

    $.post(Ajax.ajaxurl, {
      filter: filter,
      action: 'get_program_results'
    }, function(res) {
      if (res === '') {
        res = '<h3 class="no-results">No Results</h3>';
      }
      $('.search__results').fadeOut('slow', function() {
        $(this).html(res);
        $(this).fadeIn('slow');
      });
    });
  });

  $('.search-feature').scroll(function() {
    if ($('.search-form__search').is(':focus')) {
      $('.search-form__search').blur();
    }
  });
});
